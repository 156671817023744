
require('./vendor/mdb')

import * as querystring from "querystring";
global.querystring = querystring;

import {Stomp} from "@stomp/stompjs/esm6/compatibility/stomp";
import * as SockJS from 'sockjs-client';

import * as QRious from "qrious";

const {v4: uuid} = require("uuid");

import Hls from 'hls.js';

global.uuid = uuid;
global.QRious = QRious;
global.Stomp = Stomp;
global.SockJS = SockJS;
global.Hls = Hls;

