const mdb = {};
import {Button} from 'mdb5/js/mdb.min';
mdb.Button = Button;
import {Offcanvas} from 'mdb5/js/mdb.min';
mdb.Offcanvas = Offcanvas;
// import {Carousel} from 'mdb5/js/mdb.min';
// mdb.Carousel = Carousel;
// import {Popover} from 'mdb5/js/mdb.min';
// mdb.Popover = Popover;
// import {ScrollSpy} from 'mdb5/js/mdb.min';
// mdb.ScrollSpy = ScrollSpy;
import {Tab} from 'mdb5/js/mdb.min';
mdb.Tab = Tab;
import {Tooltip} from 'mdb5/js/mdb.min';
mdb.Tooltip = Tooltip;

// MDB FREE COMPONENTS
import {Input} from 'mdb5/js/mdb.min';
mdb.Input = Input;
import {Collapse} from 'mdb5/js/mdb.min';
mdb.Toast = Collapse;
import {Dropdown} from 'mdb5/js/mdb.min';
mdb.Dropdown = Dropdown;
import {Ripple} from 'mdb5/js/mdb.min';
mdb.Ripple = Ripple;
// import {Range} from 'mdb5/js/mdb.min';
// mdb.Range = Range;

// MDB PRO COMPONENTS
import {Animate} from 'mdb5/js/mdb.min';
mdb.Animate = Animate;
import {Chart} from 'mdb5/js/mdb.min';
mdb.Chart = Chart;
// import {Lightbox} from 'mdb5/js/mdb.min';
// mdb.Lightbox = Lightbox;
// import {Rating} from 'mdb5/js/mdb.min';
// mdb.Rating = Rating;
import {Sidenav} from 'mdb5/js/mdb.min';
mdb.Sidenav = Sidenav;
// import {Alert} from 'mdb5/js/mdb.min';
// mdb.Alert = Alert;
import {Toast} from 'mdb5/js/mdb.min';
mdb.Toast = Toast;
// import {Timepicker} from 'mdb5/js/mdb.min';
// mdb.Timepicker = Timepicker;
import {Navbar} from 'mdb5/js/mdb.min';
mdb.Navbar = Navbar;
import {InfiniteScroll} from 'mdb5/js/mdb.min';
mdb.InfiniteScroll = InfiniteScroll;
import {LazyLoad} from 'mdb5/js/mdb.min';
mdb.LazyLoad = LazyLoad;
// import {Datepicker} from 'mdb5/js/mdb.min';
// mdb.Datepicker = Datepicker;
// import {Popconfirm} from 'mdb5/js/mdb.min';
// mdb.Popconfirm = Popconfirm;
// import Datatable from 'mdb5/js/mdb.min';
// mdb.Datatable = Datatable;
// import {Stepper} from 'mdb5/js/mdb.min';
// mdb.Stepper = Stepper;
// import {Sticky} from 'mdb5/js/mdb.min';
// mdb.Sticky = Sticky;
import {Select} from 'mdb5/js/mdb.min';
mdb.Select = Select;
import {Touch} from 'mdb5/js/mdb.min';
mdb.Touch = Touch;
// import {SmoothScroll} from 'mdb5/js/mdb.min';
// mdb.SmoothScroll = SmoothScroll;
// import {PerfectScrollbar} from 'mdb5/js/mdb.min';
// mdb.PerfectScrollbar = PerfectScrollbar;
// import {Loading} from 'mdb5/js/mdb.min';
// mdb.Loading = Loading;
// import {Autocomplete} from 'mdb5/js/mdb.min';
// mdb.Autocomplete = Autocomplete;
import {Modal} from 'mdb5/js/mdb.min';
mdb.Modal = Modal;
// import {Clipboard} from 'mdb5/js/mdb.min';
// mdb.Clipboard = Clipboard;
// import {ChipsInput} from 'mdb5/js/mdb.min';
// mdb.ChipsInput = ChipsInput;
// import {MultiRangeSlider} from 'mdb5/js/mdb.min';
// mdb.MultiRangeSlider = MultiRangeSlider;
// import {Datetimepicker} from 'mdb5/js/mdb.min';
// mdb.Datetimepicker = Datetimepicker;




global.mdb = mdb;